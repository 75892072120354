import React from 'react'



const LanguageContext = React.createContext( {} );










class Globallanguage extends React.Component {
  constructor(props) {
    super(props);

    this.toggleLanguage = () => {
      this.setState(state => ({
        Language:
          state.Language === "english"
            ? "german"
            : "english",
      }));
    };

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      Language: "german",
      toggleLanguage: this.toggleLanguage,
    };
  }

  render() {
    // The entire state is passed to the provider
    return (
      <LanguageContext.Provider value={this.state}>

        <LanguageTogglerButton />
        <Translate de="das ist der verdammte text" en="this is the fucking text"  />

      </LanguageContext.Provider>
    );
  }
}


export default Globallanguage






function LanguageTogglerButton() {
  // The Language Toggler Button receives not only the Language
  // but also a toggleLanguage function from the context
  return (
    <LanguageContext.Consumer>
      {({Language, toggleLanguage}) => (
        <button
          onClick={toggleLanguage}>
          {Language}
        </button>
      )}
    </LanguageContext.Consumer>
  );
}



function Translate(props)

  {
 return (
    <LanguageContext.Consumer>
      {   ({Language}) =>
        {
          if (Language === "english")
            {
              return <p>{props.en}</p>
            }
          else
            {return <p>{props.de}</p>}
        }
      }
  </LanguageContext.Consumer>

);
    }
